.waitlist-component{
    position: relative;
}
.nav-container{
    position: sticky;
    top: 0;
    z-index: 1;
    background-color: white;
    width: 100%;
    /* box-shadow: 0px 1px 4px #F9F9F9; */
    border-bottom: 1px solid #0000004d;
}
.waitlist-navbar{
    width: 85%;
    margin: 0 auto;
    padding: 1em;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.waitlist-navbar .navigation-list{
    list-style: none;
    display: flex;
    gap: 2em;
    font-size: 16px;
    font-weight: 700;
}

.waitlist-navbar .navigation-list .nav-item{
    cursor: pointer;
}

.waitlist-navbar .waitlist-btn{
    display: flex;
    justify-content: flex-end;
}

.waitlist-navbar .waitlist-btn button{
    color: #fff;
    background-color: #FF614D;
    border: 1px solid #FF614D;
    border-radius: 35px;
    padding: 16px;
    font-size: 14px;
}

.waitlist-navbar .waitlist-btn button:hover{
    background-color: #E23C27;
    cursor: pointer;
}

.header-content-container{
    background-color: #EBEBEB;
}
.header-background-container{
    position: relative;
}

.header-content-hero-img{
    display: flex;
    align-items: center;
    align-items: center;
    justify-content: space-between;
    width: 85%;
    margin: 0em auto 2em auto;
    padding-top: 40px;
}
.header-content-hero-img .header-content{
    width: 52%;
    /* padding-bottom: 100px; */
    /* border: 1px solid black; */
}

.header-content-hero-img .header-img{
    width: 35%;
    /* border: 1px solid black; */
}

.header-content-hero-img .header-img .header-img-container{
    /* position: relative; */
    height: 100%;
}

/* .header-content-hero-img .header-img .header-img-container .bg-arc{
    width: 500px;
    height: 500px;
    border-radius: 50%;
    background: rgba(251, 228, 225, 0.46);
    position: absolute;
    top: 0;
    z-index: 1;
} */

.header-content-hero-img .header-img .img-container{
    width: 100%;
    height: 100%;
    padding-bottom: 0;
}

.header-content-hero-img .header-img img{
    width: 100%;
    height: 100%;
    object-fit: cover;
    display: block;
}

.header-content-hero-img .header-content h3{
    font-size: 60px;
    font-weight: 700;
}

.header-content-hero-img .header-content p{
    font-size: 18px;
    font-weight: 400;
    margin: 16px 0 2em 0;
}

.header-content-hero-img .waitlist-input-btn{
    border: 0px solid black;
}

.header-content-hero-img .waitlist-input-btn button{
    color: #fff;
    background-color: #FF614D;
    border: 0;
    padding: 24px 0;
    width: 40%;
    border-radius: 35px;
}

.header-content-hero-img .waitlist-input-btn button:hover{
    background-color: #E23C27;
    cursor: pointer;
}

.connect-professionals{
    padding-bottom: 10em;
}

.connect-professionals-container{
    width: 80%;
    margin: 0 auto;
    padding: 5em 0;
}

.connect-professionals-container .who-it-is-built-for h3{
    text-align: center;
    font-size: 32px;
    font-weight: 700;
    line-height: 140%;
}

.connect-professionals-container .who-it-is-built-for p{
    font-size: 18px;
    font-weight: 400;
    line-height: 140%;
    text-align: center;
    margin-bottom: 5em;
    width: max(500px, 50%);
    margin: 0 auto 5em auto;
}

.connect-professionals-container .professional-list{
    display: flex;
    justify-content: space-around;
}

.connect-professionals-container .professional-list .professional-img-title{
    width: 44%;
}

.connect-professionals-container .professional-list .professional-img-title img{
    width: 100%;
}

.connect-professionals-container .professional-list .professional-img-title h4{
    font-size: 28px;
    font-weight: 700;
    margin: 12px 0 16px 0;
}

.connect-professionals-container .professional-list .professional-img-title p{
    text-align: left !important;
    font-size: 16px;
    margin-bottom: 16px;
    width: 100%;
}

.connect-professionals-container .professional-list .professional-img-title button{
    padding: 16px 48px;
    background-color: #FF614D;
    color: #FFF;
    border: 1px solid #FF614D;
    border-radius: 8px;
    cursor: pointer;
}

.connect-professionals-container .professional-list .professional-img-title button:hover{
    background-color: #E23C27;
    cursor: pointer;
}

.who-we-are{
    background-color: #232222;
}

.who-we-are-container{
    display: flex;
    justify-content: space-between;
    width:85%;
    margin: 0 auto;
    padding: 5em 0 0 0;
}

.who-we-are-container .who-we-are-img{
    align-self: flex-end;
}

.who-we-are-container .who-we-are-img img{
    display: block;
}

.who-we-are-container .who-we-are-content{
    color: #fff;
    padding-top: 60px;
}

.who-we-are-container .who-we-are-content h3{
    font-size: 32px;
    font-weight: 700;
    line-height: 140%;
}
.who-we-are-container .who-we-are-content .underline{
    width: max(80px,20%);
    height: 7px;
    background-color: #FF614D;
}

.who-we-are-container .who-we-are-content p{
    font-size: 16px;
    font-weight: 400;
    line-height: 130%;
    margin: 20px 0 36px 0;
}

.who-we-are-container .who-we-are-content button{
    padding: 16px 48px;
    background-color: #FF614D;
    color: #FFF;
    border: 1px solid #FF614D;
    border-radius: 35px;
}

.who-we-are-container .who-we-are-content button:hover{
    background-color: #E23C27;
    cursor: pointer;
}

.waitlist-content-form-field{
    width: max(500px,50%);
    margin: 10em auto;
}

.waitlist-content-form-field .waitlist-content{
    width: 100%;
}

.waitlist-content-form-field .waitlist-content h3{
    font-weight: 700;
    font-size: 24px;
    padding-bottom: 60px;
    text-align: center;
}

.waitlist-content-form-field .waitlist-content p{
    font-size: 16px;
    padding-bottom: 16px;
    color: #4E4545;
}

.waitlist-content-form-field .waitlist-form-field-container{
    width: 100%;
    border-radius: 8px;
    padding: 30px;
    border: 1px solid #232222;
}

.waitlist-content-form-field .waitlist-form-field-container .form-field label{
    display: block;
}

.waitlist-content-form-field .waitlist-form-field-container .form-field input,
.waitlist-content-form-field .waitlist-form-field-container .form-field select{
    width: 100%;
    padding: 20px;
    color: #232222;
    font-size: 14px;
    border: 1px solid #D6D0CF;
    border-radius: 8px;
}

.waitlist-content-form-field .waitlist-form-field-container .form-field .select-container{
    position: relative;
}

.waitlist-content-form-field .waitlist-form-field-container .form-field .select-container .selected-option{
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: 1px solid #D6D0CF;
    border-radius: 8px;
}

.waitlist-content-form-field .waitlist-form-field-container .form-field .select-container .selected-option p{
    color: #B1B7BA;
    padding: 20px;
    font-size: 14px;
}

.waitlist-content-form-field .waitlist-form-field-container .form-field .select-container .selected-option p.active{
    color: #2E302F;
}

.waitlist-content-form-field .waitlist-form-field-container .form-field .select-container .selected-option span{
    padding-right: 5px;
}

.waitlist-content-form-field .waitlist-form-field-container .form-field .select-container  .open-select{
    display: none;
}

.waitlist-content-form-field .waitlist-form-field-container .form-field .select-container .select-options{
    width: 100%;
    position: absolute;
    top: 65px;
    z-index: 1;
    background-color: #EBEBEB;
    list-style: none;
    padding: 0;
    border-radius: 8px;
    overflow: hidden;
}

.waitlist-content-form-field .waitlist-form-field-container .form-field .select-container .select-options li{
    padding: 6px 10px;
    font-size: 14px;
    color: #2E302F;
    width: 100%;
}

.waitlist-content-form-field .waitlist-form-field-container .form-field .select-container .select-options li:hover{
    background-color: #FF614D;
    color: #fff;
}

.waitlist-content-form-field .waitlist-form-field-container .form-field input::placeholder,
.waitlist-content-form-field .waitlist-form-field-container .form-field select::placeholder{
    color: #B1B7BA;
}

.waitlist-content-form-field .waitlist-form-field-container h3{
    font-size: 28px;
    font-weight: 600;
    padding: 1em 0 16px 0;
}

.waitlist-content-form-field .waitlist-form-field-container p{
    font-size: 16px;
    padding-bottom: 3em;
}

.waitlist-content-form-field .waitlist-form-field-container .form-field input[type='submit']{
    background-color: #FF614D;
    color: #fff;
    cursor: pointer;
}

.waitlist-content-form-field .waitlist-form-field-container .form-field button{
    background-color: #FF614D;
    width: 100%;
    border: 1px solid #FF614D;
    border-radius: 8px;
    /* height: 40px; */
}

@media only screen and (max-width: 600px) {
    .header-content-container{
        padding-top: 36px;
    }
    .waitlist-navbar{
        width: 95%;
        
    }
    .waitlist-navbar .navigation-list{
        display: none;
    }
    .waitlist-navbar .logo img{
        width: 150px;
    }
    .waitlist-navbar .waitlist-btn button{
        color: #fff;
        background-color: #FF614D;
        border: 1px solid #FF614D;
        border-radius: 8px;
        padding: 13px;
        font-size: 12px;
    }
    .header-content-hero-img{
        display: block;
    }

    .header-content-hero-img .header-content{
        width: 95%;
    }
    
    .header-content-hero-img .header-img{
        width: 80%;
        margin: 0 auto;
    }

    .header-content-hero-img .header-content h3{
        font-size: 32px;
        line-height: 130%;
        padding-top: 20px;
    }
    
    .header-content-hero-img .header-content p{
        font-size: 16px;
    }

    .header-content-hero-img .waitlist-input-btn{
        border: 0px;
        margin-bottom: 14px;
    }
    
    .header-content-hero-img .waitlist-input-btn input{
        width: 100%;
        border: 1px solid #9E9A9A;
        margin-bottom: 15px;
        border-radius: 8px;
    }
    
    .header-content-hero-img .waitlist-input-btn button{
        padding: 16px 0;
        /* width: 100%; */
        width: 60%;
        border-radius: 8px;
    }

    .who-we-are{
        background: #EBEBEB;
    }

    .who-we-are-container{
        display: flex;
        flex-direction: column-reverse;
        width:95%;
        padding: 6em 0 0 0;
    }

    .who-we-are-container .who-we-are-content{
        padding-top: 0;
    }
    
    .who-we-are-container .who-we-are-content h3{
        font-size: 24px;
        color: #2E302F;
    }
    
    .who-we-are-container .who-we-are-content p{
        font-family: DM Sans;
        color: #2E302F;
        font-size: 16px;
        line-height: 130%;
        font-weight: 400;
    }
    
    .who-we-are-container .who-we-are-content button{
        display: none;
    }

    .who-we-are-container .who-we-are-img{
        width: 95%;
        margin: 0 auto;
    }
    .who-we-are-container .who-we-are-img img{
        width: 100%;
    }
    
    .connect-professionals-container{
        width: 95%;
        margin: 0 auto;
        padding: 5em 0 3em 0;
    }
    
    .connect-professionals-container .who-it-is-built-for h3{
        font-size: 24px;
        padding-bottom: 10px;
    }
    
    .connect-professionals-container .who-it-is-built-for p{
        font-size: 14px;
        margin-bottom: 3em;
    }

    .connect-professionals-container .professional-list{
        display: block;
    }
    
    .connect-professionals-container .professional-list .professional-img-title{
        width: max(80%,350px);
        margin: 0 auto 2em auto;
    }
    
    .connect-professionals-container .professional-list .professional-img-title img{
        width: 100%;
    }
    
    .connect-professionals-container .professional-list .professional-img-title h4{
        font-size: 18px;
        font-weight: 600;
        margin: 10px 0 32px 0;
    }

    .connect-professionals-container .professional-list .professional-img-title p{
        font-size: 16px;
    }

    .connect-professionals-container .professional-list .professional-img-title button{
        border-radius: 35px;
    }
    .connect-professionals-container .who-it-is-built-for p{
        width: 70%;
        margin: 0 auto 3em auto;
    }
    
    .music-curator-list{
        width: 95%;
        margin: 5em auto;
    }
    
    .music-curator-list h3{
        text-align: center;
        padding: 2.5em 0 14px 0;
        font-weight: 700;
        font-size: 22px;
    }
    
    .music-curator-list p{
        text-align: center;
        font-size: 14px;
        font-weight: 500;
        padding-bottom: 16px;
    }
    
    .music-curator-list button{
        padding: 16px 32px;
    }

    .waitlist-content-form-field{
        display: block;
        width: 95%;
    }
    
    .waitlist-content-form-field .waitlist-content{
        width: 100%;
    }
    
    .waitlist-content-form-field .waitlist-content h3{
        font-size: 22px;
    }
    
    .waitlist-content-form-field .waitlist-content p{
        font-size: 16px;
    }
    
    .waitlist-content-form-field .waitlist-form-field-container{
        width: 100%;
    }
    
}