.dashboard-footer{
    background-color: #212322;
}

.footer-container{
    width: 85%;
    padding: 4em 0 3em 0;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    color: #fff;
}

.footer-container .explore-list{
    list-style: none;
    padding: 0;
}

.footer-container .explore-list h3{
    font-size: 16px;
    font-weight: 700;
    padding-bottom: 10px;
}

.footer-container .explore-list li{
    padding-bottom: 10px;
    font-size: 16px;
    display: flex;
    align-items: center;
    gap: 10px;
}

.footer-container .explore-list li .icon{
    font-size: 24px;
}

.footer-container .explore-list li .link{
    text-decoration: none;
    color: inherit;
}

.footer-container .explore-list li .icon .link{
    font-size: inherit;
}

.footer-note{
    color: #fff;
    font-size: 14px;
    text-align: center;
    padding-bottom: 1.5em;
}

.social-media-icons{
    padding-top: 12px;
}
.social-media-icons span{
    padding-right: 10px;
    font-size: 24px;
}
.social-media-icons span .link{
    text-decoration: none;
    font-size: inherit;
    color: inherit;
}

@media only screen and (max-width: 600px){
    .footer-container{
        width: 95%;
        padding: 4em 0 3em 0;
        margin: 0 auto;
        display: block;
        justify-content: space-between;
        color: #fff;
    }

    .footer-container .explore-list li .icon{
        font-size: 20px;
    }

    .social-media-icons span{
        font-size: 20px;
    }

    .footer-container .explore-list h3{
        font-size: 16px;
        padding-top: 30px;
    }
}